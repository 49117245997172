import axios from "axios";
export let host = "https://admin.sobirov-erkin.uz/uz"; //http://62.209.129.3:8000
export let hosten = "https://admin.sobirov-erkin.uz/en";
export let hostru = "https://admin.sobirov-erkin.uz/ru";
export let host1 = "https://admin.sobirov-erkin.uz";

export let HttpRequest = (config) => {
  return axios({
    url: host,
    url: host1,
    ...config,
  });
};
